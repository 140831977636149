<template>
  <div style="margin: 20px">
    <div v-if="!isView">
      <div style="display: flex; align-items: center">
        <p class="font-bold mt-3 text-lg mb-3" style="margin-left: 10px">
          Advice List
        </p>
        <div
          style="
            flex: 1;
            width: 100%;
            display: flex;
            flex-direction: row-reverse;
          "
        >
          <div>
            <el-input
              v-model="key_word"
              placeholder="Please input key word"
              @input="fetchData"
            ></el-input>
          </div>
        </div>
      </div>

      <div
        class="bg-white py-3 px-4 mt-3"
        style="margin: 12px 0px 0; border-radius: 5px"
      >
        <p class="text-sm">
          We are continuously updating insights and advice here for you
          reference.
        </p>
      </div>
      <div>
        <activity-advice
          v-for="(item, index) in dataList"
          :key="index"
          :info="item"
          :index="index"
          @view="viewAdvice(item)"
        />
        <!--        <el-empty-->
        <!--          description="No Data Yet"-->
        <!--          v-if="dataList.length === 0"-->
        <!--        ></el-empty>-->
      </div>

      <el-dialog :visible.sync="showAdviceDialog" title="Advice Info">
        <el-form>
          <el-form-item label="Title">
            <el-input
              v-model="adviceInfo.title"
              placeholder="请输入"
              disabled="true"
            ></el-input>
          </el-form-item>
          <el-form-item label="Content">
            <el-input
              v-model="adviceInfo.content"
              type="textarea"
              :rows="5"
              disabled="true"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
    <div class="bg-white p-8" v-else>
      <div class="w-full flex justify-center">
        <div class="flex items-center" style="width: 800px">
          <i
            class="el-icon-arrow-left font-bold text-xl cursor-pointer"
            style="color: #999999"
            @click="isView = false"
          />
          <p class="text-xl ml-4 font-bold" style="color: #ff8e3b">
            {{ adviceInfo.title }}
          </p>
        </div>
      </div>
      <div class="w-full flex justify-center" style="margin-top: 25px">
        <div class="flex items-center" style="width: 800px">
          <div v-for="(tag, index) in adviceInfo.tags" :key="index">
            <p class="common-tag" style="margin-right: 15px">{{ tag }}</p>
          </div>
        </div>
      </div>
      <div class="flex justify-center">
        <div
          class="mt-8 ml-10 leading-6 rich-text"
          style="white-space: pre-line; color: #666666; max-width: 800px"
          v-html="adviceInfo.content"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getActivityAdvices } from "../../api/eae";
import ActivityAdvice from "../../components/ActivityAdvice.vue";
import "../../assets/common/common.css";
export default {
  components: { ActivityAdvice },
  name: "index",
  data() {
    return {
      dataList: [],
      adviceInfo: {
        id: "",
        title: "",
        content: "",
      },
      showAdviceDialog: false,
      isView: false,
      key_word: ''
    };
  },
  comments: {
    ActivityAdvice,
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      getActivityAdvices(this.key_word).then((res) => {
        this.dataList = res.data.data;
        loading.close();
      });
    },
    viewAdvice(row) {
      this.adviceInfo.id = row._id.$id;
      this.adviceInfo.title = row.title;
      this.adviceInfo.content = row.content;
      this.adviceInfo.tags = row.tags;
      this.isView = true;
    },
  },
};
</script>

<style lang="less">
.rich-text {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "PT Sans", "SF UI Display", ".PingFang SC", "PingFang SC",
      "Neue Haas Grotesk Text Pro", "Arial Nova", "Segoe UI", "Microsoft YaHei",
      "Microsoft JhengHei", "Helvetica Neue", "Source Han Sans SC",
      "Noto Sans CJK SC", "Source Han Sans CN", "Noto Sans SC",
      "Source Han Sans TC", "Noto Sans CJK TC", "Hiragino Sans GB", sans-serif;
    text-rendering: optimizelegibility;
    margin-bottom: 1em;
    font-weight: bold;
    line-height: 1.8rem;
  }
  ul {
    list-style-type: disc;
    list-style-position: inside;
  }
  ol {
    list-style-type: decimal;
    list-style-position: inside;
  }
}
</style>
