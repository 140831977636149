<template>
  <div
      class="activity-container p-6 bg-white my-4 flex items-center justify-between"
  >
    <div class="flex items-center">
      <div class="sort font-bold mr-6">{{ index + 1 }}</div>
      <!-- <el-image
          :src="info.advice_pic_address || require('../assets/image/cover.png')"
          class="cover mr-3"
          fit="cover"
      >
      </el-image> -->
      <div class="activity-info flex-1" style="width: 100%;padding-right: 20px;box-sizing: border-box">
        <p class="activity-name text-base font-bold mb-4 mt-3" style="margin: 2px 0px">
          {{ info.title }}
        </p>
        <div class="activity-desc">
          <p class="text-sm content" v-html="info.content"></p>
        </div>

        <div style="margin-top: 3px" class="flex">
          <div v-for="(tag,index) in info.tags" :key="index">
            <p class="common-tag" style="margin-right: 15px">{{ tag }}</p>
          </div>
        </div>

      </div>
    </div>
    <div>
      <el-button type="primary" class="mr-2" @click="handleView"
      >View
      </el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "ActivityAdvice",
  props: {
    info: Object,
    index: Number,
  },
  methods: {
    handleView() {
      this.$emit("view");
    },
  },
};
</script>

<style lang="scss" scoped>
.activity-container {
  .sort {
    font-size: 16px;
    padding: 2px 8px;
    color: var(--c-primary);
    background-color: #ffefed;
    height: fit-content;
  }

  .cover {
    width: 96px;
    height: 96px;
  }

  .content {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .activity-info {
    //height: 96px;
    .activity-name {
      color: #333333;
    }

    .activity-time {
      color: var(--c-primary);

      span {
        color: #999999;
      }

      p {
        margin-right: 45px;
      }
    }

    .activity-location {
      color: #ff8900;

      span {
        color: #999999;
      }

      p {
        margin-right: 60px;
      }
    }

    .activity-desc {
      color: #666666;
      height: 42px;
      overflow: hidden;
    }
  }
}
</style>
